// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/hgKudGhZ5";
import * as localizedValues from "./yIiHPT9db-0.js";

const enabledGestures = {OxEDQgBsI: {hover: true}};

const cycleOrder = ["OxEDQgBsI", "hVOlnbCg8"];

const serializationHash = "framer-FIKEd"

const variantClassNames = {hVOlnbCg8: "framer-v-1n9jh3d", OxEDQgBsI: "framer-v-d2sz4b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Zx6v6ZVYd: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Active: "hVOlnbCg8", Default: "OxEDQgBsI"}

const getProps = ({click2, click3, height, id, tabLabel, width, ...props}) => { return {...props, aY7fyPmy9: click2 ?? props.aY7fyPmy9, IZLijjBde: tabLabel ?? props.IZLijjBde ?? "Marketing", jaBBxopBO: click3 ?? props.jaBBxopBO, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "OxEDQgBsI"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tabLabel?: string;click2?: any;click3?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IZLijjBde, aY7fyPmy9, jaBBxopBO, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OxEDQgBsI", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaptsuf85 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (aY7fyPmy9) {const res = await aY7fyPmy9(...args);
if (res === false) return false;}
if (jaBBxopBO) {const res = await jaBBxopBO(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({hVOlnbCg8: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-d2sz4b", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"OxEDQgBsI"} onTap={onTaptsuf85} ref={ref ?? ref1} style={{borderBottomLeftRadius: 88, borderBottomRightRadius: 88, borderTopLeftRadius: 88, borderTopRightRadius: 88, ...style}} variants={{"OxEDQgBsI-hover": {borderBottomLeftRadius: 72, borderBottomRightRadius: 72, borderTopLeftRadius: 72, borderTopRightRadius: 72}}} {...addPropertyOverrides({"OxEDQgBsI-hover": {"data-framer-name": undefined}, hVOlnbCg8: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1dts917"} data-styles-preset={"hgKudGhZ5"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-1bbfdbcf-ce54-44f2-92a8-871d256315a0, rgb(17, 17, 17)))"}}>Marketing</motion.p></React.Fragment>} className={"framer-17my52i"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"uu3ME9hN1"} style={{"--extracted-r6o4lv": "var(--token-1bbfdbcf-ce54-44f2-92a8-871d256315a0, rgb(17, 17, 17))", filter: "invert(0)", WebkitFilter: "invert(0)"}} text={IZLijjBde} variants={{hVOlnbCg8: {filter: "invert(1)", WebkitFilter: "invert(1)"}}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FIKEd.framer-1cugong, .framer-FIKEd .framer-1cugong { display: block; }", ".framer-FIKEd.framer-d2sz4b { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 12px 16px 12px 16px; position: relative; width: min-content; }", ".framer-FIKEd .framer-17my52i { -webkit-user-select: none; flex: none; height: auto; pointer-events: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FIKEd.framer-d2sz4b { gap: 0px; } .framer-FIKEd.framer-d2sz4b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FIKEd.framer-d2sz4b > :first-child { margin-left: 0px; } .framer-FIKEd.framer-d2sz4b > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 95
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"hVOlnbCg8":{"layout":["auto","fixed"]},"u3wbGNy6h":{"layout":["auto","fixed"]}}}
 * @framerVariables {"IZLijjBde":"tabLabel","aY7fyPmy9":"click2","jaBBxopBO":"click3"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameryIiHPT9db: React.ComponentType<Props> = withCSS(Component, css, "framer-FIKEd") as typeof Component;
export default FrameryIiHPT9db;

FrameryIiHPT9db.displayName = "Tab";

FrameryIiHPT9db.defaultProps = {height: 40, width: 95};

addPropertyControls(FrameryIiHPT9db, {variant: {options: ["OxEDQgBsI", "hVOlnbCg8"], optionTitles: ["Default", "Active"], title: "Variant", type: ControlType.Enum}, IZLijjBde: {defaultValue: "Marketing", displayTextArea: false, title: "Tab label", type: ControlType.String}, aY7fyPmy9: {title: "Click 2", type: ControlType.EventHandler}, jaBBxopBO: {title: "Click 3", type: ControlType.EventHandler}} as any)

addFonts(FrameryIiHPT9db, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})